import "./Home.css";
import LandingPage from "../landingpage/LandingPage";


function Home() {

    return (

 <LandingPage />
    )
}

export default Home;
